<template>
    <component :is="form === undefined ? 'div' : 'b-card'">
        <template>
            <div v-if="hasPermission">
                <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                    <!-- Form -->
                    <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                        <b-row>
                            <b-col cols="12">
                                <!-- media -->
                                <b-media no-body>
                                    <b-media-body class="mt-75 ml-75">
                                        <b-row>
                                            <b-col cols="12" sm="4">
                                                <!-- upload button -->

                                                <image-cropper v-model="fileForm" label="manager image" :url.sync="form.thumbnail" />
                                                <!--/ upload button -->
                                            </b-col>
                                        </b-row>
                                        <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
                                    </b-media-body>
                                </b-media>
                                <!--/ media -->
                            </b-col>
                        </b-row>
                        <b-row class="mt-3">
                            <!-- First Name -->
                            <b-col cols="12" sm="6" md="3">
                                <validation-provider #default="validationContext" name="firstName" rules="required">
                                    <b-form-group label="First Name" label-for="first-name">
                                        <b-form-input id="first-name" v-model="form.first_name" :state="getValidationState(validationContext)" trim />

                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- Arabic First Name -->
                            <b-col cols="12" sm="6" md="3">
                                <validation-provider name="arabicFirstName">
                                    <b-form-group label="Arabic First Name" label-for="arabic-first-name">
                                        <b-form-input id="arabic-first-name" v-model="form.first_name_ar" trim />
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- Last Name -->
                            <b-col cols="12" sm="6" md="3">
                                <validation-provider name="lastName">
                                    <b-form-group label="Last Name" label-for="last_name">
                                        <b-form-input id="last_name" v-model="form.last_name" trim />
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- Arabic Last Name -->
                            <b-col cols="12" sm="6" md="3">
                                <validation-provider name="arabicLastName">
                                    <b-form-group label="Arabic Last Name" label-for="arabic_last_name">
                                        <b-form-input id="arabic_last_name" v-model="form.last_name_ar" trim />
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- User name -->
                            <b-col cols="12" sm="6" md="3">
                                <validation-provider name="username" vid="username" rules="required">
                                    <b-form-group label="User Name" label-for="username">
                                        <b-form-input id="username" v-model="form.user_name" @blur="checkExistUserName" trim :state="validUsername == true ? true : null" />

                                        <span class="text-danger">{{ usernameErrors }}</span>
                                        <small class="text-success">{{ usernameMsg }}</small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- Email -->
                            <b-col cols="12" sm="6" md="3">
                                <validation-provider #default="validationContext" name="Email" rules="required|email">
                                    <b-form-group label="Email" label-for="email">
                                        <b-form-input id="email" v-model="form.email" :state="getValidationState(validationContext)" trim />

                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- country -->
                            <b-col cols="12" sm="6" md="3">
                                <validation-provider name="Country">
                                    <b-form-group label="Country" label-for="country">
                                        <v-select v-model="form.country_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.id" :options="getCountries" label="name" :clearable="false" input-id="country" @option:selected="changeCountry" />
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- city -->
                            <b-col cols="12" sm="6" md="3">
                                <validation-provider name="City">
                                    <b-form-group label="City" label-for="city">
                                        <v-select v-model="form.city_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.id" :options="cities" :clearable="false" label="name" input-id="city" />
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- Cell number -->
                            <b-col cols="12" sm="6" md="3">
                                <validation-provider #default="validationContext" name="cell-number">
                                    <b-form-group label="Cell number" label-for="cell-number">
                                        <b-form-input id="cell-number" v-model="form.cell_number" :state="getValidationState(validationContext)" trim />

                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col cols="12" sm="6" md="4">
                                <validation-provider #default="validationContext" name="Verify Tick">
                                    <!-- <b-card-text class="mb-0"> Verify Tick </b-card-text> -->
                                    <b-form-group label="Verify Tick" label-for="verify_tick" :state="getValidationState(validationContext)">
                                        <b-form-checkbox v-model="form.have_verify_tick" input-id="verify_tick" checked="false" class="custom-control-info m-1" name="check-button" switch />
                                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- Bio-->
                            <b-col cols="12">
                                <validation-provider name="Bio">
                                    <b-form-group label="Bio" label-for="bio">
                                        <b-form-textarea v-model="form.bio" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.value" input-id="bio" />
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- Form Actions -->
                            <b-col cols="12">
                                <div class="d-flex mt-2">
                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="mr-2" type="submit">
                                        {{ artistId ? "update" : "add" }}
                                    </b-button>
                                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-danger"> reset </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
            </div>
            <div v-else class="text-center">
                <h1>⛔ You are not Allowed to be here ⛔</h1>
            </div>
        </template>
    </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback, BFormRadioGroup, BFormCheckbox } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, email } from "@validations";
import { ref, onUnmounted, onMounted, watch } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
// repository
import FileRepository from "@/abstraction/repository/fileRepository";
import ArtistRepository from "@/abstraction/repository/artistRepository";
import CityRepository from "@/abstraction/repository/cityRepository";
// random token
import { makeid } from "@/utils/math";
import useUsersList from "../user/useUsersList";
import userStoreModule from "../user/userStoreModule";
import AuthRepository from "@/abstraction/repository/authRepository";
const authRepository = new AuthRepository();
const artistRepository = new ArtistRepository();
const cityRepository = new CityRepository();
const RANDOM_TOKEN = makeid(50);

export default {
    components: {
        BTab,
        BFormInput,
        BFormInvalidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        BFormRadioGroup,
        BFormCheckbox,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    data: () => ({
        required,
        email,
        usernameErrors: "",
        usernameMsg: "",
        validUsername: undefined,
    }),
    setup() {
        const blankForm = {
            first_name: "",
            arabic_first_name: "",
            last_name: "",
            arabic_last_name: "",
            // email: "",
            bio: "",
            file_batch_id: RANDOM_TOKEN,
            is_artist: 1,
            // cell_number: "",
            user_name: "",
            have_verify_tick: false,
            last_name_ar: "",
        };
        const cities = ref([]);
        const artistId = ref(router.currentRoute.params.id);
        const fileForm = ref({
            batch_id: RANDOM_TOKEN,
            collection_name: "profile",
            model_name: "artist",
            crop: {},
        });

        const planOptions = [
            { label: "Basic", value: "basic" },
            { label: "Company", value: "company" },
            { label: "Enterprise", value: "enterprise" },
            { label: "Team", value: "team" },
        ];
        const form = ref(blankForm);

        const USER_APP_STORE_MODULE_NAME = "app-user";

        const { refetchData } = useUsersList();
        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
        });
        onMounted(async () => {
            if (artistId.value) {
                const resource = await artistRepository.show(artistId.value);
                form.value = resource;
                if (form.value.have_verify_tick == 0) {
                    form.value.have_verify_tick = false;
                } else if (form.value.have_verify_tick == 1) {
                    form.value.have_verify_tick = true;
                }
                form.value.file_batch_id = RANDOM_TOKEN;
                const resoruce = await cityRepository.index(form.value.country_id);
                cities.value = resoruce.data;
                console.log(form);
            }
        });
        const resetform = () => {
            form.value = JSON.parse(JSON.stringify(blankForm));
        };
        const onSubmit = async () => {
            console.log(form.value);
            try {
                const fileRepository = new FileRepository();
                if (fileForm.value.file) {
                    await fileRepository.store(fileForm.value);
                }
                if (router.currentRoute.params.id) {
                    await artistRepository.update(form.value.id, form.value);
                } else {
                    await artistRepository.store(form.value);
                }
                router.push({ name: "apps-artists-list" });
            } catch (e) {
                return e;
            }
        };
        watch(
            () => form.value.last_name_ar,
            (newCount, oldCount) => {
                console.log("Hello");
                if (!isArabicChars(newCount) && newCount !== oldCount) {
                    form.value.last_name_ar = "";
                }
            }
        );
        const isArabicChars = (text) => {
            var arregex = /[\u0600-\u06FF]/;
            return arregex.test(text);
        };
        const { refFormObserver, getValidationState, resetForm } = formValidation(resetform);
        const hasPermission = ref(false);
        const checkPermissions = () => {
            let logedInUserPermissions = JSON.parse(localStorage.getItem("userData")).permissions.map((item) => {
                return item.name;
            });
            if (logedInUserPermissions.includes("Artist.Singer.add")) {
                hasPermission.value = true;
            }
        };
        checkPermissions();
        return {
            hasPermission,
            form,
            refetchData,
            fileForm,
            onSubmit,
            artistId,
            cities,
            isArabicChars,
            refFormObserver,
            getValidationState,
            resetForm,
            avatarText,
            planOptions,
        };
    },
    computed: {
        ...mapGetters("country", ["getCountries"]),
    },
    methods: {
        ...mapActions("country", ["loadCountries"]),
        async changeCountry() {
            if (this.form.country_id) {
                const resoruce = await cityRepository.index(this.form.country_id);
                this.cities = resoruce.data;
            }
        },
        async checkExistUserName() {
            const form = {
                user_name: this.form.user_name,
                except_id: this.form.id,
            };
            this.usernameErrors = "";
            this.usernameMsg = "";
            if (this.form.user_name.length == 0) {
                this.usernameErrors = "";
                this.usernameMsg = "";
                this.validUsername = false;
            }
            if (this.form.user_name.length < 5) {
                this.usernameMsg = "";
                this.usernameErrors = "username must be at least 5 characters";
                this.validUsername = false;
                return;
            }
            authRepository
                .checkExistUserName(form)
                .then((res) => {
                    if (res.status == false) {
                        this.validUsername = true;
                        this.usernameMsg = "username is valid :)";
                    } else if (res.status == true) {
                        this.validUsername = false;
                        this.usernameErrors = "Unavailable Username Because it's Duplicated :(";
                    }
                })
                .catch((err) => {
                    this.usernameErrors = err.response.data.errors.user_name[0];
                    this.validUsername = false;
                });
            // let response = await authRepository.checkExistUserName(form);
            // console.log(response);
            // if (response.status) {
            //     console.log(this.$refs.refFormObserver.errors.username);
            //     this.$refs.refFormObserver.errors.username.push("this username is already taken");
            //     this.$refs.refFormObserver.setErrors("UserName", ["error"]);
            // }
        },
    },
    created() {
        this.loadCountries();
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
